<template>
  <div
    v-if="needMeetingTags"
    class="text-center w-100 mb-6"
  >
    <TTBtn
      fab
      depressed
      color="white"
      elevation="1"
      large
      data-test="aside-right-tracks-create"
      class="mb-2 v-btn--aside"
      data-test-label="add-meeting-tpl"
      @click="$root.$emit('showMeetTemplateForm')"
    >
      <VIcon
        color="tt-black"
        size="19"
      >
        fal fa-plus
      </VIcon>
    </TTBtn>
    <div class="tt-text-caption tt-black--text text--lighten-2">
      Добавить<br>шаблон
    </div>
  </div>
</template>

<script>

export default {
  name: 'AppTemplateListAsideRight',
  computed: {
    needMeetingTags() {
      return this.$di.ff.get('meeting_tags');
    },
  },
};
</script>

<style>
/* stylelint-ignore no-empty-source */
</style>
